@import '../../../../Variables';

@media (max-width: $sScr) {
	#navbar {
		flex-direction: column;
		margin: 1em auto 4em auto;
		#header {
			align-self: center;
		}
		#navbar-right {
			flex-direction: column;
			align-items: flex-start;
			align-self: center;
			margin-top: 1em;
			#brace-open {
				margin-bottom: 1em;
			}
			.navbar-right__element {
				margin-left: 2em;
				margin-bottom: 1em;
			}
		}
	}
}

@media (min-width: $sScr) {
	#navbar {
		flex-direction: column;
		align-items: center;
		margin: 2em auto 4em auto;
		#navbar-right {
			flex-direction: row;
			align-items: center;
			margin-top: 1em;
			#brace-close {
				margin-left: 1em;
			}
			.navbar-right__element {
				margin-left: 1em;
			}
		}
	}
}

@media (min-width: $lScr) {
	#navbar {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin: 3em auto 5em auto;
		#navbar-right {
			justify-content: flex-end;
			margin-top: 0;
			.navbar-right__element {
				display: flex;
				align-items: center;
			}
		}
	}
}


#navbar {
	display: flex;
	#header {
		font-size: 1.2em;
		font-weight: bold;
	}
	#navbar-right {
		display: flex;
		list-style-type: none;
		.navbar-right__element {
			a {
				text-decoration: none;
				color: $linkColor;
				&:hover {
					color: $linkColorOnHover;
				}
				&:active, &:focus {
					outline: none;
				}
			}
		}
	}
}