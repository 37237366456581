
.stack__title {
	text-align: center;
	margin-top: 2em;
}

.stack__masonry {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	margin: 4em auto 6em;
	.masonry__item {
		position: relative;
		margin-bottom: 4em;
		img {
			position: absolute;
			margin: auto;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: auto;
			height: 100%;
		}
	}
}