@import '../../../../Variables';
@import '../../../../Mixins';

@media (max-width: $xsScr) {
	.banner {
		.banner--content {
			flex-direction: column;
			.banner__text--wrapper {
				.banner__header--wrapper {
					justify-content: center;
				}
				.banner__description--wrapper {
					margin-bottom: 1.5em;
					.banner__description {
						line-height: 1.4em;
					}
				}
			}
		}
	}
}

@media (min-width: $xsScr) {
	.banner {
		.banner--content {
			flex-direction: column;
			.banner__text--wrapper {
				.banner__header--wrapper {
					justify-content: center;
				}
				.banner__description--wrapper {
					margin-bottom: 1.5em;
					.banner__description {
						line-height: 1.4em;
					}
				}
			}
		}
	}
}

@media (min-width: $lScr) {
	.banner {
		min-height: 450px;
		.banner--content {
			width: 80%;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			.banner__text--wrapper {
				width: 60%;
				.banner__header--wrapper {
					align-self: flex-start;
					.banner__header {
						font-size: 1.8em;
					}
				}
				.banner__description--wrapper {
					.banner__description {
						font-size: 1em;
						line-height: 1.6em;
					}
				}
			}
			.banner__image--wrapper {
				width: 35%;
			}
		}
	}
}

@media (min-width: $xlScr) {
	.banner {
		height: 500px;
		.banner--content {
			.banner__text--wrapper {
				width: 70%;
				.banner__header--wrapper {
					.banner__header {
						font-size: 2.5em;
					}
				}
				.banner__description--wrapper {
					.banner__description {
						font-size: 1.2em;
					}
				}
			}
			.banner__image--wrapper {
				width: 35%;
			}
		}
	}
}

.banner {

	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	-webkit-transition: all $transitionTime;
	   -moz-transition: all $transitionTime;
		-ms-transition: all $transitionTime;
		 -o-transition: all $transitionTime;
			transition: all $transitionTime;

	.banner--content {

		display: flex;

		.banner__text--wrapper {

			display: flex;
			flex-direction: column;
			justify-content: space-around;
			height: 90%;
			-webkit-transition: all $transitionTime;
			   -moz-transition: all $transitionTime;
				-ms-transition: all $transitionTime;
				 -o-transition: all $transitionTime;
					transition: all $transitionTime;

			.banner__header--wrapper {

				display: flex;
				flex-direction: row;
				-webkit-transition: all $transitionTime;
				   -moz-transition: all $transitionTime;
					-ms-transition: all $transitionTime;
					 -o-transition: all $transitionTime;
						transition: all $transitionTime;

				.banner__header {
					align-self: flex-end;
					padding: 0;
					-webkit-transition: all $transitionTime;
					   -moz-transition: all $transitionTime;
						-ms-transition: all $transitionTime;
						 -o-transition: all $transitionTime;
							transition: all $transitionTime;
				}

			}

			.banner__description--wrapper {

				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				-webkit-transition: all $transitionTime;
				   -moz-transition: all $transitionTime;
					-ms-transition: all $transitionTime;
					 -o-transition: all $transitionTime;
						transition: all $transitionTime;

				.banner__description {
					padding: 0;
					margin: 0;
					-webkit-transition: all $transitionTime;
					   -moz-transition: all $transitionTime;
						-ms-transition: all $transitionTime;
						 -o-transition: all $transitionTime;
							transition: all $transitionTime;
				}

			}

		}

		.banner__image--wrapper {

			display: flex;
			align-items: center;
			-webkit-transition: all $transitionTime;
			   -moz-transition: all $transitionTime;
				-ms-transition: all $transitionTime;
				 -o-transition: all $transitionTime;
					transition: all $transitionTime;

			.banner__image {
				max-width: 100%;
				max-height: 100%;
			}

		}

	}

}