@import '../../../../Mixins';
@import '../../../../Variables';

@media (max-width: 1300px) {
	#legal-notice-main {
		margin: 2em !important;
	}
}

#legal-notice-main {
	p {
		line-height: 1.4em;
	}
	#disclosure-requirement {
		font-weight: bold;
	}
	#contact-data {
		a {
			text-decoration: none;
			color: $linkColor;
			&:hover {
				color: $linkColorOnHover;
			}
		}
	}
	#further-text {
		margin: 2em 0;
		text-align: justify;
		@include word-break;
		@include hyphens(auto);
		a {
			color: $linkColor;
			&:hover {
				color: $linkColorOnHover;
			}
		}
	}
}