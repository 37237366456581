@import '../../../../Variables';

.sd__block-quote--wrapper {
	display: flex;
	justify-content: flex-end;
}

.sd__text-block {
	margin-top: 5em;
	margin-bottom: 5em;
}

.sd__card-wrapper {
	margin-bottom: 5em;
}

.sd__contact-button--wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 4em 0;
}