@import '../../../../Variables';
@import '../../../../Mixins';

.text-block--wrapper {
	.text-block--text {
		text-align: left;
		line-height: 1.5em;
		font-size: 1.2em;
		@include word-break;
		@include hyphens(auto);
	}
}