@import '../../../../Variables';
@import '../../../../Mixins';

.card--wrapper {
	height: 20em;
	width: 15em;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 1em;
	@include boxShadow;
	.card__image--wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 33.3%;
		width: 100%;
		.card__fa-icon {
			font-size: 2.5em;
		}
	}
	.card__text--wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 67.7%;
		width: 100%;
		.card__text__header {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 20%;
			width: 100%;
		}
		.card__text__body {
			height: 80%;
			width: 100%;
			font-size: 0.9em;
			p {
				padding-left: 1em;
				padding-right: 1em;
				text-align: center;
				@include word-break;
				@include hyphens(auto);
			}
		}
	}
}