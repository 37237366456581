@import '../../../../Variables';

@media (max-width: $xsScr) {
	.main-banner {
		height: 100px;
		.main-banner--header {
			font-size: 4vw;
			left: 7vw;
			padding-top: 15px;
		}
	}
}

@media (min-width: $xsScr) {
	.main-banner {
		height: 150px;
		.main-banner--header {
			font-size: 4vw;
			left: 7vw;
			padding-top: 15px;
		}
	}
}

@media (min-width: $sScr) {
	.main-banner {
		height: 250px;
		.main-banner--header {
			padding-top: 30px;
		}
	}
}

@media (min-width: $mScr) {
	.main-banner {
		height: 300px;
		.main-banner--header {
			left: 7vw;
			padding-top: 50px;
		}
	}
}

@media (min-width: $lScr) {
	.main-banner {
		height: 400px;
		.main-banner--header {
			font-size: 4vw;
		}
	}
}

@media (min-width: $xlScr) {
	.main-banner {
		height: 500px;
		.main-banner--header {
			font-size: 3vw;
		}
	}
}

.main-banner {
	width: 100%;
	background-repeat: no-repeat;
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center;
	-webkit-transition: height $transitionTime;
	   -moz-transition: height $transitionTime;
		-ms-transition: height $transitionTime;
		 -o-transition: height $transitionTime;
			transition: height $transitionTime;
	.main-banner--header {
		color: #ffffff;
		position: relative;
		display: inline-block;
		-webkit-transition: font-size $transitionTime;
		   -moz-transition: font-size $transitionTime;
			-ms-transition: font-size $transitionTime;
			 -o-transition: font-size $transitionTime;
				transition: font-size $transitionTime;
	}
}