@import './Variables';
@import './Fonts';

@media (max-width: $xlScr) {
	.limit-width {
		width: 90%;
	}
}

@media (min-width: $xlScr) {
	.limit-width {
		width: 1300px;
	}
}

.limit-width {
	margin: 0 auto;
}

.app {
	margin: 0 auto;
	font-family: OpenSans, sans-serif;
	color: black;
}

h4 { font-size: 2em; }
h5 { font-size: 1.2em; }
