/** Colors **/
$textColor: #000000;
$linkColor: #000000;
$linkColorOnHover: #444444;
$linkColorOnActive: #555555;

$npGreen: #215830;
$npDarkGreenGray: #2f4f4f;
$npRed: #c90000;
$npBlue: #000f4d;

/** Font size **/
$standardFontSize: 0.9em;

/** screen sizes **/
$xsScr: 400px;
$sScr: 550px;
$mScr: 660px;
$lScr: 750px;
$xlScr: 1300px;

/** transitions **/
$transitionTime: 0.3s;

:export {
	npGreen: $npGreen;
	npDarkGreenGray: $npDarkGreenGray;
	npRed: $npRed;
	npBlue: $npBlue;
	active: $linkColorOnActive;
}
