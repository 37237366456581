@font-face {
  font-family: OpenSans;
  src: url('assets/fonts/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: OpenSans;
  src: url('assets/fonts/OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: OpenSans;
  src: url('assets/fonts/OpenSans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
