@import '../../../../Variables';

.block-quote--wrapper {

	display: flex;
	justify-content: flex-end;

	.block-quote--bracket {
		font-size: 3.5em;
		font-family: Arial sans-serif;
	}

	.block-quote--bracket--left {
		margin-right: 0.5em;
	}

	.block-quote--bracket--right {
		margin-left: 0.5em;
	}

	.block-quote--quote {

		display: flex;
		flex-direction: column;
		align-items: flex-end;
		padding: 0.5em;

		.block-quote--quote__text {
			font-size: 1.2em;
			font-style: italic;
			margin-bottom: 0.5em;
			text-align: right;
		}
	}
}