@import '../../../../Variables';

@media (max-width: 400px) {
	#home--wrapper {
		margin-top: 2em;
		#name-landing {
			font-size: 1.4em !important;
			text-align: center;
			margin: 2em auto 4.5em auto;
		}
		#navigation-landing {
			font-size: 1.4em ;
			margin-bottom: 5.5em !important;
		}
	}
}

@media (min-width: 401px) {
	#home--wrapper {
		margin-top: 4em;
		#name-landing {
			text-align: center;
			margin: 2em auto 3em auto;
		}
		#navigation-landing {
			font-size: 1.8em;
			margin-bottom: 3em !important;
		}
	}
}

@media (min-width: 620px) {
	#home--wrapper {
		margin-top: 5em;
		#name-landing {
			text-align: left;
			margin: 2em auto;
		}
		#navigation-landing {
			font-size: 1.8em;
		}
	}
}

#home--wrapper {

	-webkit-transition: margin-top $transitionTime;
	   -moz-transition: margin-top $transitionTime;
		-ms-transition: margin-top $transitionTime;
		 -o-transition: margin-top $transitionTime;
			transition: margin-top $transitionTime;
	
	#name-landing {
		font-size: 2em;
		font-weight: bold;
	}

	#navigation-landing {

		text-align: right;
		list-style-type: none;
		margin: 0 0 4em 0;

		li {
			padding-bottom: 1em;

			a {
				text-decoration: none;
				color: $linkColor;

				&:hover {
					color: $linkColorOnHover;
				}

			}

		}
	}
}