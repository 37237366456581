@import '../../../../Variables';
@import '../../../../Mixins';

.contact-button {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 18em;
	height: 4em;
	text-decoration: none;
	&:not(:active) {
		@include boxShadow;
	}

	&:hover {
		cursor: pointer;
	}

	.contact-button__message {
		height: 100%;
		width: 75%;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 1.5em;
		text-align: center;
		@include disableSelect;
	}

	.contact-button__icon {
		background-color: #f1f1f1;
		height: 100%;
		width: 25%;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 2em;
		text-align: center;
		color: black;
		&:visited {
			color: black;
		}
	}
}