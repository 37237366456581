@import '../../../../Variables';

#languages {
	display: flex;
	flex-direction: row;
	.lang {
		text-transform: uppercase;
	}
	.selectableLang {
		&:hover {
			color: $linkColorOnHover;
			cursor: pointer;
		}
	}
	.selectedLang {
		text-decoration: underline;
	}
	.separator {
		margin: 0 2px;
	}
}