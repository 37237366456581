@import '../../../../Variables';
@import '../../../../Mixins';

@media (max-width: $xsScr) {
	.split-banner--wrapper {
		flex-direction: column;
		.split-banner__inner--wrapper {
			flex-direction: column;
			justify-content: space-between;
			width: 100%;
			.split-banner__inner {
				width: 90%;
				.split-banner__image--wrapper {
					.split-banner__image {
						height: 75%;
					}
				}
			}
		}
	}
}

@media (min-width: $xsScr) {
	.split-banner--wrapper {
		flex-direction: column;
		.split-banner__inner--wrapper {
			flex-direction: column;
			justify-content: space-between;
			width: 100%;
			.split-banner__inner {
				width: 90%;
				.split-banner__image--wrapper {
					.split-banner__image {
						height: 80%;
					}
				}
			}
		}
	}
}

@media (min-width: $lScr) {
	.split-banner--wrapper {
		.split-banner__inner--wrapper {
			flex-direction: row;
			.split-banner__inner {
				width: 48%;
			}
		}
	}
}

.split-banner--wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	text-align: center;
	.split-banner__inner--wrapper {
		display: flex;
		.split-banner__inner {
			display: flex;
			flex-direction: row;
			justify-content: space-evenly;
			align-items: center;
			background-color: #fafafa;
			height: 200px;
			margin: 2em 0;
			@include boxShadow;
			.split-banner__image--wrapper {
				display: flex;
				align-items: center;
				height: 8em;
				.split--banner__image {
					width: auto;
					-webkit-transition: all $transitionTime;
					   -moz-transition: all $transitionTime;
						-ms-transition: all $transitionTime;
						 -o-transition: all $transitionTime;
							transition: all $transitionTime;
				}
			}
		}
		.split-banner__left--wrapper {
			align-self: flex-start;
		}
		.split-banner__right--wrapper {
			align-self: flex-end;
		}
	}
}