@import '../../../../Variables';

@media (max-width: $sScr) {
	.footer__contribution-wrapper {
		text-align: center;
	}

	#footer {
		flex-direction: column;
		padding-top: 3em;
		#footer-left {
			order: 2;
			padding-top: 3em;
		}
		#footer-right {
			flex-direction: column;
			order: 1;
			width: 80%;
			div {
				display: flex;
				flex-direction: column;
				align-items: center;
				.footer-right__item a {
					padding: 0.6em;
				}
			}
			.footer-right__wrapper-right .footer-right__right-item {
				text-align: right;
			}
		}
	}
}

@media (min-width: $sScr) {
	.footer__contribution-wrapper {
		text-align: center;
	}

	#footer {
		flex-direction: column;
		#footer-left {
			order: 2;
			padding-top: 1em;
		}
		#footer-right {
			flex-direction: row;
			justify-content: center;
			order: 1;
			.footer-right__item {
				&:last-child a {
					padding-right: 0;
				}
				a {
					padding-right: 1em;
				}
			}
		}
	}
}

@media (min-width: $sScr) {
	#footer {
		flex-direction: column;
		#footer-left {
			order: 2;
			padding-top: 1em;
		}
		#footer-right {
			justify-content: center;
			order: 1;
			.footer-right__item {
				&:last-child a {
					padding-right: 0;
				}
				a {
					padding-right: 1em;
				}
			}
		}
	}
}

@media (min-width: $lScr) {
	.footer__contribution-wrapper {
		text-align: left;
	}

	#footer {
		flex-direction: row;
		justify-content: space-between;
		#footer-left {
			justify-content: flex-start;
			order: 1;
			padding-top: 0;
		}
		#footer-right {
			justify-content: flex-end;
			order: 2;
			.footer-right__item {
				&:last-child a {
					padding-right: 0;
				}
				a {
					padding-right: 1em;
				}
			}
		}
	}
}

.footer__contribution-wrapper {
	font-size: 0.8em;
	padding-bottom: 1em;
	a {
		text-decoration: none;
		color: $linkColor;
	}
	&:hover a {
		color: $linkColorOnHover;
	}
}

#main-footer {
	margin-top: 5em;
	margin-bottom: 4em;
}

#footer {
	display: flex;
	align-items: center;
	font-size: 0.8em;
	#footer-left {
		display: flex;
		flex-direction: row;
		#name {
			margin-right: 1em;
			a {
				text-decoration: none;
				color: $linkColor;
				&:hover {
					color: $linkColorOnHover;
				}
			}
		}
	}
	#footer-right {
		display: flex;
		.footer-right__item {
			a {
				text-decoration: none;
				color: $linkColor;
				&:hover {
					color: $linkColorOnHover;
				}
				&:active, &:focus {
					outline: none;
				}
			}
		}
	}
}