@import '../../../../Mixins';
@import '../../../../Variables';

@mixin grid-wrapper() {
	.side-text {
		grid-area: sideText;
	}
	.side-image {
		grid-area: sideImage;
	}
	#contact {
		grid-area: contact;
	}
	#tech-stack {
		grid-area: techStack;
	}
}

@media (max-width: $mScr) {
	#about--main {
		@include grid-wrapper;
		grid-template-rows: auto auto auto;
		grid-template-columns: auto;
		grid-template-areas:
				'sideText'
				'sideImage'
				'contact'
				'techStack';
		#cv {
			margin-bottom: 2em;
			#cv-header {
				text-align: center;
			}
			.cv-entry-header {
				text-align: center;
			}
		}
		#skills {
			margin: 2em 0;
			#skills-header {
				text-align: center;
			}
		}
		.side-image {
			margin: 2em auto 2em auto;
			width: 45%;
			height: auto;
		}
		#contact {
			margin: 0;
			text-align: center;
			h4 {
				text-align: center;
			}
			a {
				display: block;
				text-align: center;
			}
		}
	}
}

@media (min-width: $mScr) {
	#about--main {
		@include grid-wrapper;
		grid-template-columns: 55% 15% 30%;
		grid-template-rows: auto auto;
		grid-template-areas:
				'sideText . sideImage'
				'sideText . contact'
				'techStack techStack techStack';
		.side-image {
			width: 100%;
			height: auto;
		}
	}
}

#about--main {
	display: grid;
	.side-text {
		text-align: left;
		@include word-break;
		@include hyphens(auto);
		font-size: $standardFontSize;
		#cv-header {
			margin-top: 0;
		}
	}
	.side-image {
		img {
			width: 100%;
			height: auto;
		}
	}
	#contact {
		text-align: left;
		a {
			font-size: $standardFontSize;
			text-decoration: none;
			color: $linkColor;
			&:hover {
				color: $linkColorOnHover;
			}
		}
	}

}