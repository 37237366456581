@import '../../../../Variables';
@import '../../../../Mixins';

.wd__block-quote--wrapper {
	display: flex;
	justify-content: flex-end;
}

.wd__text-block {
	margin-top: 5em;
	margin-bottom: 5em;
}

.wd__contact-button--wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 4em 0;
}