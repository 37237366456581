@mixin hyphens($prop) {
	-webkit-hyphens: $prop;
	   -moz-hyphens: $prop;
		-ms-hyphens: $prop;
		 -o-hyphens: $prop;
			hyphens: $prop;
}

@mixin greyscaleFilter($percent) {
	-webkit-filter: grayscale($percent);
			filter: grayscale($percent);
}

@mixin word-break() {
	-ms-word-break: break-word;
		word-break: break-word;
}

@mixin rotate($degrees) {
	-webkit-transform: rotate($degrees);
	   -moz-transform: rotate($degrees);
		-ms-transform: rotate($degrees);
		 -o-transform: rotate($degrees);
			transform: rotate($degrees);
}

@mixin boxShadow() {
	-webkit-box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1), 0 2px 5px 0 rgba(0, 0, 0, 0.1);
	   -moz-box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1), 0 2px 5px 0 rgba(0, 0, 0, 0.1);
			box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1), 0 2px 5px 0 rgba(0, 0, 0, 0.1);
}

@mixin disableSelect() {
	-webkit-user-select: none;
	   -moz-user-select: none;
		-ms-user-select: none;
			user-select: none;
}